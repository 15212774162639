<template>
  <div class="router-wrap">
    <div class="router-banner"></div>
    <div class="newsDetails-content">
      <ul class="tab-box">
        <Back
          :name="tabName"
          backtop="25px"
          backright="40px"
          homeBack="/JoinUsDetails"
          :homeName="langTrue == 'en-us' ? 'join us' : '加入我们'"
        />

        <li class="tab-item active">
          {{ tabName }}
        </li>
      </ul>

      <div>
        <div class="details-content">
          <div class="details-top">
            <p>{{ recruitmentdata.job }}</p>
            <div>
              <p>
                <!-- {{ langTrue == "en-us" ? "Location：" : "工作地点："
                }}{{ recruitmentdata.address }} | -->
                {{ langTrue == "en-us" ? "hands-on background：" : "工作经验："
                }}{{ recruitmentdata.experience }} |
                {{ langTrue == "en-us" ? "education：" : "学历："
                }}{{ recruitmentdata.education }} |
                {{ langTrue == "en-us" ? "Number of recruits：" : "招聘人数："
                }}{{ recruitmentdata.nums }}
                {{ langTrue == "en-us" ? "" : "" }}
              </p>
              <span>{{ recruitmentdata.time }}</span>
            </div>
          </div>
          <div class="details-bottom">
            <p class="details-title">
              {{ langTrue == "en-us" ? "Job description：" : "职责描述：" }}
            </p>
            <div>
              <!-- 1、参与商务谈判，起草和审核公司合同、外发函件等各项法律文件，协助制定、完善各类标准格式合同。<br />
              2、协助搭建维护集团内控合规管理及法律风险管理体系。<br />
              3、根据法律法规、监管规定及公司相关制度要求，对公司规章制度、业务流程进行合规性审阅，确保其合规性。<br />
              4、定期开展合规风险评估，定期整理报送各类合规风险信息。遇有重大违规事件时，即时上报并积极采取控制措施。<br />
              5、领导交办的其他工作。<br /> -->
              <el-input
                type="textarea"
                class="textarea"
                :rows="2"
                autosize
                placeholder=""
                disabled
                v-model="recruitmentdata.description"
              ></el-input>
            </div>
            <p class="details-title">
              {{ langTrue == "en-us" ? "Requirements：" : "任职要求：" }}
            </p>
            <div>
              <!-- 1、第一学历本科以上，法律、法学等相关专业，211、985、一流大学院校优先考虑；<br />
              2、具备法务合规专业知识，取得司法职业资格者优先；<br />
              3、具有组织协调能力、沟通能力、团队协作精神及问题解决能力；<br />
              4、思维敏捷缜密，为人正真，原则性强；<br />
              5、定居上海，居住在上海浦东新区为佳。<br /> -->
              <el-input
                type="textarea"
                :rows="2"
                class="textarea"
                autosize
                placeholder=""
                disabled
                v-model="recruitmentdata.requirements"
              ></el-input>
            </div>

            <p class="details-title-bottom">
              {{ langTrue == "en-us" ? "Location：" : "工作地点："
              }}{{ recruitmentdata.address }}
            </p>

            <p class="details-title-bottom">
              {{ langTrue == "en-us" ? "Resume delivery：" : "简历投递："
              }}{{ recruitmentdata.delivery }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Back from "@/components/back";
import { selectWantInfoList, wantInfoDetail } from "@/api/common.js";
import NoData from "@/components/noData.vue";
export default {
  name: "JoinUsDetails",
  data() {
    return {
      langTrue: sessionStorage.getItem("langType"),
      jovlistId: "",
      activeTab: 1,
      tabName:
        sessionStorage.getItem("langType") == "en-us"
          ? sessionStorage.getItem("JoinUsItemNameEn")
          : sessionStorage.getItem("JoinUsItemName"),
      tabList: [
        {
          name:
            sessionStorage.getItem("langType") == "en-us"
              ? "Social recruitment"
              : "社会招聘",
          id: 1,
        },
        {
          name:
            sessionStorage.getItem("langType") == "en-us"
              ? "Campus Recruitment"
              : "校园招聘",
          id: 2,
        },
      ],
      joblistlength: 0,
      jobsList: [
        // {
        //   experience: "3-5年",
        //   job: "UI设计师",
        //   education: "本科",
        //   nums: "若干人",
        //   address: "上海市 - 浦东新区",
        // },
      ],
      currentPage: 1,

      recruitmentdata: {
        job: "",
        address: "",
        experience: "",
        education: "",
        nums: "",
        time: "",
        description: "",
        requirements: "",
        delivery: "",
      },
    };
  },
  components: {
    Footer,
    Back,
    NoData,
  },

  created() {
    this.showDetailsclick(sessionStorage.getItem("JoinUsItemId"));
  },
  methods: {
    showDetailsclick: function (val) {
      this.jovlistId = val;
      this.getwantInfoDetail();
    },

    getwantInfoDetail() {
      wantInfoDetail({
        id: this.jovlistId,
        lang: sessionStorage.getItem("langType"),
      }).then((res) => {
        let data = res.data;
        this.recruitmentdata = {
          job: data.postName,
          address: data.workingPlace,
          experience: data.experience,
          education: data.education,
          nums: data.wantNum,
          time: data.publishTime,
          description: data.duty,
          requirements: data.requirements,
          delivery: data.email,
        };
      });
    },
  },
};
</script>
<style scoped lang="scss">
.router-banner {
  display: flex;
  height: 662px;
  background: url("../assets/imgs/joinUs/joinUs-banner.jpg") center center;
}
.newsDetails-content {
  width: 1200px;
  min-height: 741px;
  margin: 0 auto;
  padding-top: 30px;
  position: relative;
  .tab-box {
    display: flex;
    margin-bottom: 40px;
    .tab-item {
      cursor: pointer;
      color: #444;
      font-size: 16px;
      height: 50px;
      line-height: 50px;
      margin-right: 74px;
      border-bottom: 2px solid transparent;
    }
    .tab-item::after {
      content: "";
      display: block;
      transition: all 200ms ease-in-out 0s;
      cursor: pointer;
      height: 2px;
      background: #0072cb;
      margin: 0 auto;
      width: 0;
    }
    .tab-item:hover,
    .tab-item.active {
      color: #0072cb;
    }
    .tab-item:hover::after,
    .tab-item.active::after {
      width: 100%;
    }
  }
  .job-banner {
    height: 286px;
    display: flex;
    margin-bottom: 30px;
  }
  .job-list {
    li {
      margin-bottom: 30px;
      background: #f6f6f6;
      padding: 27px 80px 27px 66px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      line-height: 1;
      height: 115px;
      cursor: pointer;
      & > p {
        font-size: 18px;
        color: #060606;
      }
      & > div {
        font-size: 16px;
        color: #444;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 85px;
    .pagination-first,
    .pagination-last {
      width: 82px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #eee;
      cursor: pointer;
    }
  }

  .details-content {
    .details-top {
      border-bottom: 1px solid #b3b3b3;
      padding: 0 40px 18px;
      margin-bottom: 20px;
      & > p {
        font-size: 20px;
        color: #444;
        margin-bottom: 25px;
      }
      & > div {
        font-size: 18px;
        display: flex;
        justify-content: space-between;

        & > span {
          color: #b3b3b3;
        }
      }
    }
    .details-bottom {
      padding: 0 40px 18px;
      font-size: 14px;
      color: #666;
      line-height: 30px;
      .details-title {
        font-size: 16px;
        color: #444;
      }
      .details-title-bottom {
        font-size: 16px;
        color: #666;
      }
      & > div {
        margin-bottom: 24px;
        .textarea::v-deep .el-textarea__inner {
          border: none;
          outline: none;
          width: 100%;
          font-size: 14px;
          resize: none;
          color: #666;
          line-height: 30px;
          background: #fff;
          font-family: "Microsoft YaHei", "微软雅黑", simsun, "宋体", tahoma,
            arial;
        }
      }
    }
  }
}
</style>
